import AppWrapper from "../../layouts/AppWrapper";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

const PersonasContainer = () => {
  const [hideSideBar, setHideSideBar] = useState(false);
  const [sideContentOne, setSideContentOne] = useState("");
  const [sideContentTwo, setSideContentTwo] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/personas/select") {
      setSideContentOne("ペルソナをつくる");
      setSideContentTwo("タイトルを選んでください");
    } else if (location.pathname === "/personas/create") {
      setSideContentOne("ペルソナの新規発行");
      setSideContentTwo("各種設定項目を入力してください");
    }
  }, [location.pathname]);

  return (
    <AppWrapper
      bgMask={true}
      isAnimateSideBar
      hideSideBar={hideSideBar}
      sideContentOne={sideContentOne}
      sideContentTwo={sideContentTwo}
    >
      <Outlet context={[hideSideBar, setHideSideBar]} />
    </AppWrapper>
  );
};

export default PersonasContainer;

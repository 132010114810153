import { useState } from "react";
import {
  DropOfHeavenIcon,
  PersonaIcon,
  EverGreenTreeIcon,
  SolidRockIcon,
  CrimsonFlameIcon,
  UnrivaledSteelIcon,
  GoldenLightIcon,
  BottomLessAbyssIcon,
  PenIcon,
} from "../assets/icons";
import { translations } from "../services/localization";
import Button from "./Button";
import { TextInput } from "./Form";
import { fetchData } from "../services/fetch";
import { useMutation } from "react-query";
import { storeErrorModal } from "../services/storage";

const elements = [
  "heavenly_dew_element", //drop_of_heaven_element
  "crimson_flame_element", //red_lotus_flame_element
  "golden_light_element",
  "eternal_tree_element", //everlasting_tree_element
  "peerless_steel_element", //warriors_steel_element
  "unfathomable_abyss_element",
  "adamantine_rock_element", //solid_rocks_element
];

export const PersonaQtyUpdate = ({
  setShowQtyModal,
  personaId,
  personaToken,
  setShowLoadingModal,
  setIsLoadProgress,
  setBatchId,
  setValidModal,
}) => {
  const contents = translations.personaDetail;
  const [values, setValues] = useState({
    persona_qty: "",
    eternal_tree_element: "",
    crimson_flame_element: "",
    adamantine_rock_element: "",
    peerless_steel_element: "",
    heavenly_dew_element: "",
    golden_light_element: "",
    unfathomable_abyss_element: "",
  });

  const handleChange = (prop) => (event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    if (elements.includes(prop)) {
      let total = elements.reduce((a, c) => a + Number(values[c]), 0);
      total = total - Number(values[prop]) + Number(event.target.value);
      if (total <= values.persona_qty) {
        setValues({ ...values, [prop]: Number(event.target.value) });
      }
      return;
    }
    setValues({ ...values, [prop]: Number(event.target.value) });
  };

  const postQTY = async (data) => {
    const response = await fetchData({
      url: `api/persona_type/${personaId}/add-persona-tokens`,
      method: "post",
      data: data,
    });
    return response;
  };

  const { mutate: updateQTY, isLoading } = useMutation(
    async (data) => postQTY(data),
    {
      onError: async (err) => {
        if (err.response.data.errors) {
          console.log(err.response.data.errors);
        }
        storeErrorModal(err);
      },
      onSuccess: async (data) => {
        setBatchId(data.data.batchId);
        setValues({
          persona_qty: "",
          eternal_tree_element: "",
          crimson_flame_element: "",
          adamantine_rock_element: "",
          peerless_steel_element: "",
          heavenly_dew_element: "",
          golden_light_element: "",
          unfathomable_abyss_element: "",
        });
        setShowQtyModal(false);
        setIsLoadProgress(true);
        setShowLoadingModal(true);
      },
    }
  );

  const handleUpdate = () => {
    if (
      !values.persona_qty
      // !values.eternal_tree_element ||
      // !values.crimson_flame_element ||
      // !values.adamantine_rock_element ||
      // !values.peerless_steel_element ||
      // !values.heavenly_dew_element ||
      // !values.golden_light_element ||
      // !values.unfathomable_abyss_element
    ) {
      setValidModal({ show: true, message: "必須項目を埋めてください。" });
      return;
    }
    let total = elements.reduce((a, c) => a + Number(values[c]), 0);
    if (total !== values.persona_qty) {
      setValidModal({
        show: true,
        message: "発行数がelementsの合計数と一致していません。",
      });
      return;
    }
    updateQTY({ ...values });
  };

  return (
    <div className="mx-30-px my-4">
      <section>
        <h3 className="font-ethno-light fs-25-px mb-4 text-center">
          <PersonaIcon className="op-1 mr-10-px" /> ペルソナ追加発行
        </h3>
        <p className="mb-1 text-center">
          あと (65535 - 現在の発行数) 枚
          発行できます。発行数を入力して発行を押してください。
        </p>
        <div className="row my-5 justify-content-center align-items-center w-70 mx-auto">
          <div className="col-6 d-flex flex-column align-items-center">
            <label className="font-notosans-jp label-text mb-10-px">
              発行数
            </label>
            <TextInput
              width="193px"
              inputClass="text-center left-placeholder text-highlight font-ethno-normal fs-20-px"
              inactive
              value={values.persona_qty}
              onChange={handleChange("persona_qty")}
            />
          </div>
          <div className="col-6 d-flex flex-column align-items-center">
            <label className="font-notosans-jp label-text mb-10-px">
              現在の発行数
            </label>
            <p className="font-ethno-normal fs-20-px text-highlight">
              {personaToken}
            </p>
          </div>
        </div>
      </section>
      <section className="mb-4">
        <p className="font-notosans-jp label-text mb-4">
          {contents.element.label}
          <span className="fs-14-px text-light-primary ml-50-px">
            {contents.element_secondary}
          </span>
        </p>
        <div className="row pl-30-px">
          <div className="col-6">
            <div className="row align-items-center mb-3">
              <label className="font-notosans-jp label-text col-6">
                <DropOfHeavenIcon className="mr-10-px" />
                天の雫
              </label>
              <div className="col-6 d-flex align-items-center">
                <TextInput
                  width="110px"
                  inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                  inactive
                  placeholder="---"
                  value={values.heavenly_dew_element}
                  onChange={handleChange("heavenly_dew_element")}
                />
                <PenIcon className="ml-10-px" />
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <label className="font-notosans-jp label-text col-6">
                <EverGreenTreeIcon className="mr-10-px" />
                常磐なる樹
              </label>
              <div className="col-6 d-flex align-items-center">
                <TextInput
                  width="110px"
                  inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                  inactive
                  placeholder="---"
                  value={values.eternal_tree_element}
                  onChange={handleChange("eternal_tree_element")}
                />
                <PenIcon className="ml-10-px" />
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <label className="font-notosans-jp label-text col-6">
                <SolidRockIcon className="mr-10-px" />
                堅牢な岩
              </label>
              <div className="col-6 d-flex align-items-center">
                <TextInput
                  width="110px"
                  inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                  inactive
                  placeholder="---"
                  value={values.adamantine_rock_element}
                  onChange={handleChange("adamantine_rock_element")}
                />
                <PenIcon className="ml-10-px" />
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <label className="font-notosans-jp label-text col-6">
                <BottomLessAbyssIcon className="mr-10-px" />
                底知れぬ深淵
              </label>
              <div className="col-6 d-flex align-items-center">
                <TextInput
                  width="110px"
                  inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                  inactive
                  placeholder="---"
                  value={values.unfathomable_abyss_element}
                  onChange={handleChange("unfathomable_abyss_element")}
                />
                <PenIcon className="ml-10-px" />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row align-items-center mb-3">
              <label className="font-notosans-jp label-text col-6">
                <CrimsonFlameIcon className="mr-10-px" />
                紅蓮の焔
              </label>
              <div className="col-6 d-flex align-items-center">
                <TextInput
                  width="110px"
                  inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                  inactive
                  placeholder="---"
                  value={values.crimson_flame_element}
                  onChange={handleChange("crimson_flame_element")}
                />
                <PenIcon className="ml-10-px" />
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <label className="font-notosans-jp label-text col-6">
                <UnrivaledSteelIcon className="mr-10-px" />
                無双の鋼
              </label>
              <div className="col-6 d-flex align-items-center">
                <TextInput
                  width="110px"
                  inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                  inactive
                  placeholder="---"
                  value={values.peerless_steel_element}
                  onChange={handleChange("peerless_steel_element")}
                />
                <PenIcon className="ml-10-px" />
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <label className="font-notosans-jp label-text col-6">
                <GoldenLightIcon className="mr-10-px" />
                黄金の光
              </label>
              <div className="col-6 d-flex align-items-center">
                <TextInput
                  width="110px"
                  inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                  inactive
                  placeholder="---"
                  value={values.golden_light_element}
                  onChange={handleChange("golden_light_element")}
                />
                <PenIcon className="ml-10-px" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-flex justify-content-center gap-5 my-3">
        <Button
          onClick={() => {
            setShowQtyModal(false);
          }}
          text="CANCEL"
          className="w-175-px h-40-px"
          buttonClass="button-dialog"
        ></Button>
        <Button
          onClick={handleUpdate}
          text="発行"
          className="w-175-px h-40-px"
          buttonClass="button-dialog"
          disabled={isLoading}
        ></Button>
      </section>
    </div>
  );
};

import React, { useContext } from "react";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import { TextInput } from "../components/Form";
import { AuthContext } from "../context/authContext";
import { formatNumber } from "../utils/truncate";

/** Content Body */
const Setting = () => {
  const { user } = useContext(AuthContext);

  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        // title={<Title />
        className="setting-content"
        title={
          <div className="mt-1 text-primary" style={{ marginLeft: "0.7rem" }}>
            設定
          </div>
        }
        topSpace={<TopSpace />}
        smallMarginRight
      >
        <div
          className="text-white"
          style={{ fontSize: "18px", marginLeft: "40px" }}
        >
          <table>
            <tr>
              <td className="pb-3" style={{ width: "241px" }}>
                スクエア ID
              </td>
              <td className="py-3">{user?.tenant?.square_id}</td>
            </tr>
            <tr>
              <td className="py-3">ライセンス</td>
              <td className="py-3">Standard</td>
            </tr>
            <tr>
              <td className="py-3">アカウント権限</td>
              <td className="py-3">Master</td>
            </tr>
            <tr>
              <td className="py-3">言語</td>
              <td className="py-3">日本語</td>
            </tr>
            <tr>
              <td className="py-3">タイムゾーン</td>
              <td className="py-3">東京, 日本（GMT+09:00）</td>
            </tr>
            <tr>
              <td className="py-3">ANM クレジット残高</td>
              <td className="py-3">
                {formatNumber(user?.tenant?.anm_amount)} ANM
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "top" }} className="py-3">
                カスタムオプション
              </td>
              <td style={{ verticalAlign: "top" }} className="py-3">
                {/* <p className="pb-4">シード設定</p> */}
                <p>アフィリエイト</p>
              </td>
            </tr>
          </table>
        </div>
      </ContentWrapper>
    </AppWrapper>
  );
};

// スクエア ID ライセンス アカウント権限 言語 タイムゾーン ANM クレジット残高 カスタムオプション
// LQAY2JA Standard Master 日本語 東京, 日本（GMT+09:00） 1,000,000 ANM シード設定 アフィリエイト
/** Title */
// const Title = () => {
//   return <div className="d-flex">{/* Title Content */}</div>;
// };

/** Top Space */
const TopSpace = () => {
  return <></>;
};

export default Setting;

import { React, useRef, useState } from "react";
import { SearchIcon } from "../assets/icons";
import "../assets/scss/select.scss";

export const TextInput = (props) => {
  const {
    type = "text",
    value,
    defaultValue,
    onChange = () => {},
    onClick,
    placeholder,
    width,
    height,
    className,
    inputClass,
    required,
    isError,
    errorMessage,
    errorClass,
    borderColor,
    textboxClass,
    inactive,
    readonly,
    pattern,
    onKeyDown,
    isLeftIcon,
    leftIcon,
    isTextArea = false,
    disabled,
  } = props;

  return (
    <>
      <div
        className={`text-input ${className}`}
        style={{ width: width, height: height }}
      >
        <div
          className={`text-box ${
            errorMessage || isError ? "error-border" : ""
          } ${
            inactive && !isError && !errorMessage ? "inactive" : ""
          } ${textboxClass} ${disabled ? "disabled" : ""} `}
          style={
            borderColor
              ? {
                  backgroundColor: borderColor,
                }
              : {}
          }
        ></div>

        {isTextArea ? (
          <textarea
            value={value}
            defaultValue={defaultValue}
            onClick={onClick}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            readOnly={readonly}
            className={`textarea hide-placeholder ${
              inputClass ? inputClass : "fs-20-px"
            }`}
            disabled={disabled}
          ></textarea>
        ) : (
          <input
            type={type}
            className={`input hide-placeholder ${
              inputClass ? inputClass : "fs-20-px"
            }`} //you can set font size from inputClass
            value={value}
            defaultValue={defaultValue}
            onClick={onClick}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            readOnly={readonly}
            pattern={pattern}
            onKeyDown={onKeyDown}
            disabled={disabled}
          />
        )}
        {isLeftIcon === true && (
          <div className="form-control-right-icon">{leftIcon}</div>
        )}
        {errorMessage && (
          <div className={` ${errorClass ? errorClass : "error"}`}>
            {errorMessage}
          </div>
        )}
      </div>
    </>
  );
};

export const NumberInput = (props) => {
  const {
    value,
    defaultValue,
    onChange = () => {},
    onClick,
    placeholder,
    width,
    height,
    className,
    inputClass,
    required,
    isError,
    errorMessage,
    errorClass,
    borderColor,
    textboxClass,
    inactive,
    readonly,
    pattern,
    onKeyDown,
    isLeftIcon,
    leftIcon,
    disabled,
  } = props;
  const [displayedValue, setDisplayedValue] = useState(value);
  const update = (event) => {
    // Allow only numbers, commas, and a single optional dot
    let val = event.target.value
      .replace(/[^0-9,.]/g, "")
      .replace(/(\..*)\./g, "$1");

    const caret = event.target.selectionStart;
    const element = event.target;
    const currentCursor = val.length - caret

    // Convert to an integer (by stripping out commas and decimals)
    let x = parseInt(val.replace(/,/g, ""), 10);

    // If input is invalid or results in NaN, reset to empty string
    if (isNaN(x)) {
      setDisplayedValue("");
      onChange(0)
      return;
    }

    // Format as integer without decimals
    let currentVal = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(x);

    // Update the displayed value only if it has changed
    if (displayedValue !== currentVal) {
      setDisplayedValue(currentVal);
      onChange(x)
    }

    // Restore cursor position
    window.requestAnimationFrame(() => {
      element.selectionStart = currentVal.length - currentCursor;
      element.selectionEnd = currentVal.length - currentCursor;
    });
  };

  return (
    <>
      <div
        className={`text-input ${className}`}
        style={{ width: width, height: height }}
      >
        <div
          className={`text-box ${
            errorMessage || isError ? "error-border" : ""
          } ${
            inactive && !isError && !errorMessage ? "inactive" : ""
          } ${textboxClass} ${disabled ? "disabled" : ""} `}
          style={
            borderColor
              ? {
                  backgroundColor: borderColor,
                }
              : {}
          }
        ></div>

        {
          <input
            type="text"
            className={`input hide-placeholder ${
              inputClass ? inputClass : "fs-20-px"
            }`} //you can set font size from inputClass
            value={displayedValue}
            defaultValue={defaultValue}
            onClick={onClick}
            onChange={update}
            placeholder={placeholder}
            required={required}
            readOnly={readonly}
            pattern={pattern}
            onKeyDown={onKeyDown}
            disabled={disabled}
          />
        }
        {isLeftIcon === true && (
          <div className="form-control-right-icon">{leftIcon}</div>
        )}
        {errorMessage && (
          <div className={` ${errorClass ? errorClass : "error"}`}>
            {errorMessage}
          </div>
        )}
      </div>
    </>
  );
};

export const LoginInput = (props) => {
  const {
    type = "text",
    value,
    onChange,
    onBlur,
    placeholder,
    width,
    height,
    className,
    inputClass,
    required,
    id,
  } = props;

  return (
    <div
      className={`login-input ${className}`}
      style={{ width: width, height: height }}
    >
      <div className="left-wrapper"></div>
      <div className="text-box"></div>
      <input
        type={type}
        className={`input ${
          type === "password" ? "fs-30-px pb-1" : "fs-20-px"
        } ${inputClass}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        required={required}
        id={id}
        autoComplete="off"
        style={{ letterSpacing: type === "password" ? 4 : "" }}
      />
      <div className="right-wrapper"></div>
    </div>
  );
};

export const SearchInput = (props) => {
  const {
    value,
    onChange,
    placeholder,
    width,
    height,
    className,
    required,
    onKeyDown,
  } = props;

  return (
    <div
      onKeyDown={onKeyDown}
      className={`search-input ${className}`}
      style={{ maxWidth: "100%", width: width, height: height }}
    >
      <div className="search-box d-flex justify-content-start">
        <div className="search-icon d-flex align-items-center">
          <SearchIcon />
        </div>
        <input
          type="text"
          className="input smaller-placeholder hide-placeholder"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
        />
      </div>
    </div>
  );
};

export const SelectInput = ({
  id,
  value,
  onChange,
  options = { 整数: "integer", 実数: "float", 文字列: "string" },
  placeholder = "データ型を選択",
  styles,
  className,
  containerClassName,
  disabled,
}) => {
  const selectRef = useRef(null);
  const selectBoxRef = useRef(null);
  const selectedValue = Object.entries(options).filter((o) =>
    o.includes(value)
  )[0]?.[0];
  const optionsToShow = Object.keys(options).filter(
    (option) => option !== selectedValue
  );

  const handleSelectOption = (value) => {
    if (disabled) return;
    selectRef.current.blur();
    setTimeout(() => {
      onChange({ target: { value: value } });
    }, 500);
  };

  const handleIcon = () => {
    if (selectBoxRef.current.clientHeight === 40) {
      selectRef.current.focus();
    }
  };

  return (
    <div
      className={`select ${containerClassName}`}
      style={{ ...styles }}
      ref={selectRef}
      tabIndex="0"
      data-id={id}
    >
      <div
        className={`select-box select-box-${
          selectedValue ? optionsToShow.length + 1 : optionsToShow.length
        }`}
        ref={selectBoxRef}
      ></div>
      <span className={`value ${className}`}>
        {selectedValue === undefined ? (
          <span className="select-placeholder">{placeholder}</span>
        ) : (
          <span role="button" tabIndex="0" style={{ width: "100%" }}>
            {selectedValue}
          </span>
        )}
      </span>
      <ul className={`optList ${selectedValue ? "selected" : ""}`}>
        {optionsToShow.map((option) => (
          <li
            style={{ width: "100%" }}
            className={`option ${className}`}
            key={option}
            onClick={() => handleSelectOption(options[option])}
          >
            {option}
          </li>
        ))}
      </ul>
      <div
        role="button"
        tabIndex="0"
        className="icon"
        onClick={handleIcon}
      ></div>
    </div>
  );
};
